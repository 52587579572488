import { Draggable, Sortable, Plugins } from "@shopify/draggable";
import onmount from 'onmount';
import Rails from '@rails/ujs';
import MicroModal from 'micromodal';
import Cookies from 'js-cookie';
const tippy = require('tippy.js').default;

var lodash_isEmpty = require('lodash/isEmpty');
var lodash_isEqual = require('lodash/isEqual');

var resetWidth = true;
var sortable_detail = null;
var sortable = null;

var list_steps = [];
var curr_list_change = {};
var updated_notes = {};
var origin_form_fields = {};

var old_list_positions = [];
var new_entries = {};

var curr_entry = null;

var search_cache = {};
var last_query = "";

var tippy_folder_instances = [];

function initalizeSortable(horizontal) {
  if(sortable != null) {
    sortable.destroy();
  }
  sortable = new Sortable(document.querySelectorAll('#list-grid'), {
    draggable: '.draggable-list-entry',
    handle: ".list-entry-handle",
    swapAnimation: {
      duration: 150,
      easingFunction: 'ease-in-out',
      horizontal: horizontal
    },
    plugins: [Plugins.SwapAnimation]
  });

  sortable.on('sortable:sort', () => {
      if(resetWidth) {
          if(horizontal) {
              $('.draggable-mirror').width($('.draggable-list-entry .grid-mode').width());
          } else {
              $('.draggable-mirror').width($('.draggable-list-entry .detail-mode').width());
          }
          resetWidth = false;
      }
      console.log("Sorting lists...");
  });

  sortable.on('sortable:stop', (evt) => {
    resetWidth = true;
    moveElement($(evt.newContainer).children('.draggable-list-entry'), curr_entry, evt.oldIndex, evt.newIndex);
    curr_entry = null;
  });

  sortable.on('sortable:start', (evt) => {
    curr_entry = $(evt.startContainer).children('.draggable-list-entry').get(evt.startIndex).getAttribute('entry_id');
  });
}

// Swaps elements in both view modes in Edit List
function moveElement(elements, entryId, oldIndex, newIndex) {

  if(oldIndex == newIndex) {
    return;
  }

  let a = elements.get(oldIndex);
  let b = elements.get(newIndex);

  if($.isEmptyObject(curr_list_change)) {
    curr_list_change = {'type': 'change', 'entryId': entryId, 'old': oldIndex, 'new': newIndex};
  } else {
    if(curr_list_change['new'] == oldIndex) {
      curr_list_change['new'] = newIndex;
    // } else if (curr_list_change['new'] == newIndex && curr_list_change['old'] != oldIndex) {
    //   curr_list_change['new'] = oldIndex; // Seemed to only cause bugs
    } else {
      list_steps.push(curr_list_change);
      curr_list_change = {'type': 'change', 'entryId': entryId, 'old': oldIndex, 'new': newIndex};
    }

    if(curr_list_change['new'] == curr_list_change['old']) {
      curr_list_change = {};
    }
  }

  //console.log(list_steps);
  //console.log(curr_list_change);
}

onmount('#list-modal #add-to-list-save', function() {
    $(this).on('click', function() {
        MicroModal.close('list-modal');
        const game_id =  $('#list-modal').attr('game_id');

        let list_ids = [];

        $('#list-container input.add_list_checkbox:checked').each(function() {
            list_ids.push($(this).attr('list_id'));
        });

        $.ajax({
          type: "POST",
          url: "/api/list/quick/" + game_id,
          data: {'list_ids': list_ids}
        });
    });
});

// Prevents list form from submitting when Enter is pressed - #1281
onmount('.list-form', function(){
  $(".list-form :input:not(textarea)").on('keydown', function(event){
    return event.key != "Enter";
  });
});

onmount('#create-debug-list', function(){
  $(this).on('click', function(){
     const input = prompt("Enter list entry data", "");
     const list_id = $('.list-form')[0].id

    $.ajax({
      type: "POST",
      url: '/api/list/' + list_id + '/debug-new-entries/',
      data: {new_entries: input},
      success(res) {
        window.location.reload(false);
      }
    });
  });
});

onmount('#move-debug-list', function(){
  $(this).on('click', function(){
    const input = prompt("Enter list entry data", "");
    const list_id = $('.list-form')[0].id
    let new_list_positions = [];
    let entry_ids = input.split(',');

    if (input.length == 0) {
      return;
    }

    entry_ids.forEach(entry_id => new_list_positions.push(entry_id.replace(/\D/g,'')));

    $.ajax({
      type: "PUT",
      url: "/api/list/" + list_id + "/update-entries-2/",
      data: {prev_order: old_list_positions, new_order: new_list_positions, new_entries: new_entries, curr_notes: updated_notes},
      success() {
        Rails.fire($('.list-form')[0], 'submit');
      }
    });
  });
});

onmount('.discard-list-changes', function(){
  $(this).on('click', function(){
    const list_id = $('.list-form')[0].id

    $.ajax({
      type: 'PATCH',
      url: '/api/list/' + list_id + '/discard-entries/',
      success(res) {
        window.location.reload(false);
      }
    });
  })
});

onmount('button.create-list', function() {
  $(this).on('click', function(){
    $.ajax({
      type: 'POST',
      url: '/api/new-list/'
    });
  });
});

onmount('#open-folders, #close-folders', function(){
  $(this).on('click', function() {
    $('#folder-selector').toggleClass('collapsed');

    if ($('#folder-selector').hasClass('collapsed')) {
      Cookies.set('collapsed_folder_view', true, { sameSite: 'strict', expires: 365 });
      tippy_folder_instances.forEach(element => element.enable());
    } else {
      Cookies.remove('collapsed_folder_view');
      tippy_folder_instances.forEach(element => element.disable());
    }
  });
});

onmount('#folder-selector', function(){
  tippy_folder_instances = tippy('#folder-selector .folder-tooltip', {
    offset: [0, 5],
    animation: 'shift-away',
    placement: "right",
    theme: 'backloggd',
    duration: 150
  });
  const collapse_view = Cookies.get('collapsed_folder_view');
  if (collapse_view) {
    $('#folder-selector').addClass('collapsed');
  } else {
    $('#folder-selector').removeClass('collapsed');
    tippy_folder_instances.forEach(element => element.disable());
  }

  if ($(this)[0].scrollHeight > $(this)[0].clientHeight) {
    $('#folder-selector').addClass('scrollbar-padding');
  }
});

onmount('#create-folder', function() {
  $(this).on('click', function() {

    let list_ids = [];

    $('#list-folder-container input[name="folder_list"]:checked').each(function() {
        list_ids.push($(this).val());
    });
    
    $.ajax({
      type: "POST",
      url: '/api/list/folder',
      data: {
        folder_id: $(this).attr('folder_id'),
        title: $('#list_folder_name').val(),
        default_sort: $('#default_folder_sorting').val(),
        default_sort_dir: $('#default_folder_sorting_dir').val(),
        list_ids: list_ids
      },
      success(res) {
        MicroModal.close('list-folder-modal');
        window.location.href = res.slug;
      }
    });
  });
});

onmount('#default_folder_sorting', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const curr_val = $('#default_folder_sorting option[value="' + $(this).selectpicker('val') + '"]').attr('default_dir');
    $('#default_folder_sorting_dir').selectpicker('val', curr_val == 'asc' ? 1 : 0);
  });
});

onmount('#default_list_sorting', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const curr_val = $('#default_list_sorting option[value="' + $(this).selectpicker('val') + '"]').attr('default_dir');
    $('#default_list_sorting_dir').selectpicker('val', curr_val == 'asc' ? 1 : 0);
  });
});

onmount('#default_game_sorting', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const curr_val = $('#default_game_sorting option[value="' + $(this).selectpicker('val') + '"]').attr('default_dir');
    $('#default_game_sorting_dir').selectpicker('val', curr_val);
  });
});

onmount('#default_backlog_sorting', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const curr_val = $('#default_backlog_sorting option[value="' + $(this).selectpicker('val') + '"]').attr('default_dir');
    $('#default_backlog_sorting_dir').selectpicker('val', curr_val);
  });
});

onmount('#default_playing_sorting', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const curr_val = $('#default_playing_sorting option[value="' + $(this).selectpicker('val') + '"]').attr('default_dir');
    $('#default_playing_sorting_dir').selectpicker('val', curr_val);
  });
});

onmount('#default_wishlist_sorting', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const curr_val = $('#default_wishlist_sorting option[value="' + $(this).selectpicker('val') + '"]').attr('default_dir');
    $('#default_wishlist_sorting_dir').selectpicker('val', curr_val);
  });
});

onmount('#delete-folder', function() {
  $(this).on('click', function() {
    if(confirm("Are you sure you want to delete this folder?")) {
      $.ajax({
        type: "DELETE",
        url: '/api/list/folder',
        data: {folder_id: $(this).attr('folder_id')}
      });
    }
  });
});

onmount('#edit-list-note-modal #note', function(){
  $(this).on('input', function() {
    const entry_id = $('#edit-list-note-modal').attr('entry_id');
    if($('.grid-list-entry[entry_id="' + entry_id + '"]').attr('note') != $(this).val()) {
      $('#edit-list-note-modal #save-note').prop('disabled', false);
    } else {
      $('#edit-list-note-modal #save-note').prop('disabled', true);
    }
  });
});

onmount('#edit-list-note-modal #save-note', function(){
  $('#edit-list-note-modal #save-note').prop('disabled', true);

  $(this).on('click', function() {
      const entry_id = $('#edit-list-note-modal').attr('entry_id');
      const note = $('#edit-list-note-modal #note').val();

      $('#edit-list-note-modal #save-note').prop('disabled', true);
      updated_notes[entry_id] = note;
      $('.grid-list-entry[entry_id="' + entry_id + '"]').attr('note', note);
      MicroModal.close('edit-list-note-modal');
  });
});

onmount('.delete-entry', function() {
  $(this).on('click', function(){
    if(!$.isEmptyObject(curr_list_change)) {
      list_steps.push(curr_list_change);
      curr_list_change = {};
    }

    const entry = $(this).parent().parent().parent().parent().parent();
    const entry_id = parseInt(entry.attr('entry_id'));
    if(list_steps.length > 0 && list_steps[list_steps.length - 1]['type'] == "add" && list_steps[list_steps.length - 1]['temp_id'] == entry_id) {
      list_steps.splice(-1,1);
    } else {
      list_steps.push({'type': 'remove', 'entry_id': entry_id});
    }
    delete new_entries[entry_id]
    entry.remove();
  });
});

var newEntryCounter = -1;

onmount('#list-grid .draggable-list-entry', function() {
  if($(this).attr('entry_id').length < 1) {
    $(this).attr('entry_id', newEntryCounter);
    list_steps.push({'type': 'add', 'game_id': $(this).attr('game_id'), 'temp_id': newEntryCounter});
    new_entries[newEntryCounter] = $(this).attr('game_id');
    newEntryCounter -= 1;
  }
});

function getFormFields() {
  const possible_fields = ['list[name]', 'list[privacy]', 'list[ranked]', 'list[style]', 'list[desc]', 'default_list_sorting']
  let list_form_fields = {};

  $.each($('.list-form').serializeArray(), function(index, field){
    if ($.inArray(field['name'], possible_fields) > -1) {
      list_form_fields[field['name']] = field['value'];
    }
  });

  return list_form_fields;
}

function isListDirty() {
  let new_list_positions = [];
  let curr_form_fields = getFormFields();

  $('.draggable-list .draggable-list-entry').each(function(index){
    new_list_positions.push($(this).attr('entry_id'));
  });

  return !lodash_isEmpty(updated_notes) || 
  !lodash_isEmpty(new_entries) || 
  !lodash_isEqual(old_list_positions, new_list_positions) ||
  !lodash_isEqual(origin_form_fields, curr_form_fields);
}

onmount('#list-view', function() {
  $(this).on('click', function(){
    window.location.replace($(this).attr('redirect_url'));
  });
});

onmount('#list-submit', function() {
  list_steps = [];
  curr_list_change = {};
  updated_notes = {};
  old_list_positions = [];
  new_entries = {};
  origin_form_fields = getFormFields();

  $('.draggable-list .draggable-list-entry').each(function(index){
    old_list_positions.push($(this).attr('entry_id'));
  });

  $(window).bind('beforeunload', function(e){
    if(isListDirty()) {
      const confirmationMessage = 'You have unsaved changes.\nAre you sure you want to leave?';
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }

    return undefined;
  });

  $(this).on('click', function() {

    $(this).prop('disabled', true);
    $(this).html('Saving...');
    $('#list_game_search').prop('disabled', true);

    if(!$.isEmptyObject(curr_list_change)) {
      list_steps.push(curr_list_change);
    }

    let new_list_positions = [];

    $('.draggable-list .draggable-list-entry').each(function(index){
      new_list_positions.push($(this).attr('entry_id'));
    });

    $.ajax({
        type: "PUT",
        url: "/api/list/" + $(this).attr('list_id') + "/update-entries-2/",
        data: {prev_order: old_list_positions, new_order: new_list_positions, new_entries: new_entries, curr_notes: updated_notes},
        success() {
          Rails.fire($('.list-form')[0], 'submit');
        }
    });

    $('#list-grid').html('<div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>');
  });
});

// Only load when on list edit page
onmount('.draggable-list#list-grid', function() {
  initalizeSortable($('#list_style')[0].checked);
});

// List view mode toggle
onmount('#list_style', function() {
  $(this).on('change', function(){
    const grid_mode = $('#list_style')[0].checked;
    $('.grid-mode-element, .detail-mode-element').toggleClass('d-none');
    if (grid_mode) {
      $('.draggable-list-entry').attr('class', 'col-cus-3 col-md-cus-5 mt-2 px-1 grid-list-entry draggable-list-entry');
      $('.draggable-list-entry .main-list-entry-row').removeClass('detail-mode detail-list-entry');
      $('.draggable-list-entry .main-list-entry-row').addClass('grid-mode');
      $('.draggable-list-entry .list-entry-cover-col').removeClass('col-2 col-sm-1 col-lg-cus-19 col-xl-cus-23 px-0');
      $('.draggable-list-entry .list-entry-cover-col').addClass('col');
    } else {
      $('.draggable-list-entry').attr('class', 'col-12 mt-2 grid-list-entry draggable-list-entry');
      $('.draggable-list-entry .main-list-entry-row').removeClass('grid-mode');
      $('.draggable-list-entry .main-list-entry-row').addClass('detail-mode detail-list-entry');
      $('.draggable-list-entry .list-entry-cover-col').removeClass('col');
      $('.draggable-list-entry .list-entry-cover-col').addClass('col-2 col-sm-1 col-lg-cus-19 col-xl-cus-23 px-0');
    }
    initalizeSortable(grid_mode);
  });
});
